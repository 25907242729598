<template>
  <div style="height: calc(100vh - 130px); position:relative;">
    <mx-preloader :z-index="9" :loader-status="preloader"/>
    <v-container fluid>
      <v-card class="mb-6 pa-5">
        <h2 class="title mb-4">{{ $t('document.create.title') }}</h2>
        <v-row>
          <v-col lg="6">
            <v-text-field
                v-model="form.name"
                outlined
                hide-details
                :label="$t('document.name')"
                :placeholder="$t('document.name')"
                required
                :error="errors.name!==undefined"
            ></v-text-field>
          </v-col>
          <v-col lg="3">
            <v-select
                outlined
                v-model="form.for"
                :items="select_for"
                :label="$t('document.for.select')"
                :append-icon="'mdi-chevron-down'"
                hide-details
                @waiting="filterCategory"
                :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>
          </v-col>
          <v-col lg="3">
            <v-select
                outlined
                v-model="form.category_id"
                :items="filterCategory"
                :label="$t('document.category.select')"
                :append-icon="'mdi-chevron-down'"
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
                item-text="name"
                item-value="id"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="9">
            <v-file-input
                v-model="form.file"
                :label="$t('document.placeholder_file')"
                :placeholder="$t('document.placeholder_file')"
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                outlined
                :show-size="1000"
                :error="errors.file!==undefined"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip v-if="index < 4" color="primary" dark label small>
                  {{ text }}
                </v-chip>

                <span v-else-if="index === 4" class="text-overline grey--text text--darken-3 mx-2">
                  +{{ form.file.length - 4 }} File(s)
                </span>
              </template>
            </v-file-input>
          </v-col>
          <v-col lg="3">
            <v-select
                :disabled="form.for === 'employee'"
                outlined
                v-model="form.for_all"
                :items="select_for_all"
                :label="$t('document.for_all.select', {element: $t(`document.for.${form.for}`)})"
                :append-icon="'mdi-chevron-down'"
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>
          </v-col>
        </v-row>
      </v-card>

<!--      <v-card style="position:relative;" class="pa-5 mb-6" v-if="!form.for_all && (form.for === 'employee' || form.for === 'all')">-->
<!--        <h4 class="mb-6">{{ $t('document.employee.title') }}</h4>-->
<!--        <MultiSelectEmployee @result="resultSelectEmployees"/>-->
<!--      </v-card>-->

      <v-card style="position:relative;" class="pa-5 mb-6" v-if="!form.for_all && (form.for === 'customer' || form.for === 'all')">
        <h4 class="mb-6">{{ $t('document.customer.title') }}</h4>
        <MultiSelectCustomer :selected="form.customers" @result="resultSelectCustomers"/>
      </v-card>

      <v-card class="pa-5">
        <v-row>
          <v-col cols="12" sm="6" md="3" lg="2" class="pr-0 ml-auto">
            <v-btn
                style="width: 100%; padding: 28px;"
                elevation="0"
                color="green"
                @click="documentCreate"
                :loading="btn_create_loading"
                :disabled="btn_create_loading"
            >
              {{ $t('document.category.buttons.save') }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <v-btn
                style="width: 100%; padding: 28px;"
                elevation="0"
                @click="backToDocuments"
            >
              {{ $t('document.category.buttons.abort') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import MultiSelectEmployee from "@/components/Employee/MultiSelectEmployee";
import MultiSelectCustomer from "@/components/Customer/MultiSelectCustomer";

export default {
  components: {MultiSelectEmployee, MultiSelectCustomer},
  data() {
    return {
      preloader: true,
      preloader_employee: true,
      btn_create_loading: false,
      form: {
        name: '',
        employees: [],
        customers: [],
        teams: [],
        roles: [],
        file: null,
        for: 'employee',
        for_all: 1,
        category_id: 1
      },
      select_for: [
        {
          text: this.$t('document.for.employee'),
          value: 'employee'
        },
        {
          text: this.$t('document.for.customer'),
          value: 'customer'
        },
        // {
        //   text: this.$t('document.for.all'),
        //   value: 'all'
        // },
      ],
      select_category: [],
      select_for_all: [
        {
          text: this.$t('document.for_all.yes'),
          value: 1
        },
        {
          text: this.$t('document.for_all.no'),
          value: 0
        },
      ],
      errors: []
    }
  },
  watch: {
    'form.for'() {
      if (this.form.for === 'employee') {
        this.form.for_all = 1
      }
    },
    'form.for_all'() {
      if (this.form.for_all === 1) {
        this.form.employees = []
        this.form.customers = []
        this.form.teams = []
        this.form.roles = []
      }
    }
  },
  computed: {
    filterCategory() {
      this.form.category_id = 1

      return this.select_category.filter( category => {
        if (this.form.for === 'all') {
          return category.employee === 1 && category.customer === 1
        }

        if (this.form.for === 'employee') {
          return category.employee === 1
        }

        if (this.form.for === 'customer') {
          return category.customer === 1
        }
      })
    },
  },
  created () {
    if (this.$route.params.customer_uid !== undefined) {
      this.form.customers.push(parseInt(this.$route.params.customer_uid))
      this.form.for_all = 0
      this.form.for = 'customer'
    }
    this.getCategories()
    setTimeout(() => {this.preloader = false}, 300)
  },
  methods: {
    resultSelectEmployees(employees) {
      this.form.employees = employees
    },
    resultSelectCustomers(customers) {
      this.form.customers = customers
    },
    getCategories() {
      this.$store.dispatch('documentCategories').then(() => {
        this.select_category = this.$store.getters.document_categories
      })
    },
    documentCreate() {
      this.btn_create_loading = true

      if (this.form.for_all) {
        this.form.employees = []
        this.form.customers = []
        this.form.teams = []
        this.form.roles = []
      }

      let formData =  new FormData()
      formData.append('file', this.form.file)
      formData.set('name', this.form.name)
      formData.set('for', this.form.for)
      formData.set('for_all', this.form.for_all)
      formData.set('employees', this.form.employees)
      formData.set('customers', this.form.customers)
      formData.set('teams', this.form.teams)
      formData.set('roles', this.form.roles)
      formData.set('created_employee_id', this.$store.getters.user.uid)
      formData.set('category_id', this.form.category_id)

      this.$store.dispatch('documentCreate', formData)
          .then(() => {this.backToDocuments()})
          .catch(error => {
          this.btn_create_loading = false
          switch (error.response.status) {
            case 422:
              this.errors = error.response.data.errors;
              break;
          }
        })
    },
    backToDocuments() {
      this.$router.go(-1)
      // this.$router.replace({
      //   name: 'documents.documents'
      // })
    }
  }
}
</script>
